import './App.css';
import { AboutUs } from './components/AboutUs';
import { Contact } from './components/Contact';
import Culture from './components/Culture';
import { Footer } from './components/Footer';
import Hero from './components/Hero';
import { Service } from './components/Service';


function App() {
  return (
    <div className="App">
      <Hero/>
      <Culture/>
      <Service/>
      <AboutUs/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default App;
