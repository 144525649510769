import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  color: white;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 2rem;
    text-align: left;
    margin-bottom: 0;
    max-width: 60%;
  }
`;

const Btn = styled.a`
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.4px;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
    padding: 10px 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Contact = () => {
  return (
    <Content>
      <Title>FORME PARTE DE LA CONSTRUCCIÓN DE LA INFRAESTRUCTURA DEL PLANETA</Title>
      <Btn href="mailto:contact@ubicuo.xyz">Contactar</Btn>
    </Content>
  );
};

export default Contact;
