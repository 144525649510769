import React from 'react'
import styled from 'styled-components';


const Container = styled.div   `
display: flex;
padding-left: 20px;
margin-top: 50px;
margin-bottom: 50px;
align-items: center;
flex-direction: column;
@media (min-width: 768px) {
    flex-direction: row;
  }

`

const LeftContent = styled.div `


@media (min-width: 768px) {
    width: 80%;
    font-size: 2rem;

  }
`

const ContentHeader = styled.h3 `
font-size: 2rem

@media (min-width: 768px) {
    font-size: 2rem;

  }

`
const ListaElementos = styled.ul `


`
const ElementList = styled.li `
font-size: 1rem


`

const RightContent = styled.div `


`

const Image = styled.img `
    max-height: 500px;
`


export const Service = () => {




    
  return (
    <Container id="servicio">
        <LeftContent>
            <ContentHeader>UBICUO inicialemente se centra en la optimización del sistema de producción de desarrollo web estándar</ContentHeader>
            <ListaElementos>
                <ElementList><strong>Entrega ágil:</strong> Desarrollos web con tiempos de entrega reducidos, asegurando rapidez y eficiencia.
                
                </ElementList>
                <ElementList>
                <strong>Diseño atractivo y funcional:</strong> Estética coherente y navegación fluida para una experiencia de usuario superior.
                </ElementList>
                <ElementList>
                <strong>Enfoque en conversión:</strong> Estructuras web optimizadas para dirigir el tráfico hacia eventos de conversión relevantes.
                </ElementList>


            </ListaElementos>


            
        </LeftContent>
        <RightContent>
            <Image src="/landing-co.jpg"></Image>
        </RightContent>
    </Container>
  )
}
