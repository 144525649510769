import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MenuNav = styled.nav``;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoUbicuo = styled.img`
  margin: 0 auto;
  max-width: 200px;
`;

const ContainerMedia = styled.div``;

const Para = styled.p`
  text-align: center;
`;

const Media = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
`;

const Element = styled.li`
  font-size: 24px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    color: black; // Cambia el color al hacer hover
  }
`;

const SocialLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Privacidad = styled.p`
  text-align: center;
  cursor: ponter;
  &:hover {
    opacity: 0.8;// Cambia el color al hacer hover
  }
`

export const Footer = () => {
  return (
    <Container>
      <MenuNav></MenuNav>
      <ContentInfo>
        <LogoUbicuo src='/logo-blanco.png'></LogoUbicuo>
        <ContainerMedia>
          <Para>Infraestructura optimizada para el ensamblaje de la nueva realidad</Para>
          <Media>
            <Element>
              <SocialLink href="https://www.linkedin.com/company/ubicuoxyz/">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </SocialLink>
            </Element>
            <Element>
              <SocialLink href="https://x.com/ubicuoxyz">
                <FontAwesomeIcon icon={faXTwitter} />
              </SocialLink>
            </Element>
            <Element>
              <SocialLink href="https://www.instagram.com/ubicuo.xyz/">
                <FontAwesomeIcon icon={faInstagram} />
              </SocialLink>
            </Element>
          </Media>
          <Privacidad>Privavidad & Cookies</Privacidad>
        </ContainerMedia>
      </ContentInfo>
    </Container>
  );
};
