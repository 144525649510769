import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from '../App';
import NotFound from '../components/NotFound';
import { Manifiesto } from '../components/Manifiesto';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>} />
        <Route path="manifiesto" element={<Manifiesto/>} />
        <Route path="*" element={<NotFound/>} /> {/* Ruta para manejar cualquier URL no encontrada */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
