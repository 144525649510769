import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Nav from './Nav';

const Typewriter = ({ text }) => {
  const getVariants = index => ({
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: index * 0.1 }
    }
  });

  return (
    <TextContainer>
      {text.split('').map((char, index) => (
        <motion.div
          key={index}
          initial="hidden"
          animate="visible"
          variants={getVariants(index)}
          style={{ display: 'inline-block' }}
        >
          {char === ' ' ? '\u00A0' : char}
        </motion.div>
      ))}
    </TextContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const NavContainer = styled.div`
  // Estilos para el Nav
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; // Ocupa el espacio restante
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Manifiesto = () => {
  return (
    <MainContainer>
      <NavContainer>
        <Nav />
      </NavContainer>
      <ContentContainer>
        <Typewriter text="Terminal y Nesbant están construyendo" />
      </ContentContainer>
    </MainContainer>
  );
}
