import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1000;
`;

const DesktopMenu = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s;

  &:hover {
    color: #ccc;
  }
`;

const NavLogo = styled.img`
  max-width: 200px;
  @media (max-width: 768px) {
    max-width: 120px;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #333;
  border: 1px solid #555;
  padding: 10px;
  z-index: 1001;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileMenuItem = styled.a`
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s;

  &:hover {
    color: #ccc;
  }
`;

const Nav = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const homePath = '/'; // Asegúrate de que esto coincida con la ruta de tu página de inicio

  return (
    <NavContainer>
      <Link to="/">
        <NavLogo src="/logo-blanco.png" alt="Logo" />
      </Link>
      <DesktopMenu>
        <NavLink href={`${homePath}#servicio`}>Servicio</NavLink>
        <NavLink href={`${homePath}#cultura`}>Cultura</NavLink>
        <NavLink href={`${homePath}#las-personas`}>Las personas</NavLink>
      </DesktopMenu>
      <MobileMenuIcon onClick={toggleMobileMenu}>&#9776;</MobileMenuIcon>
      <MobileMenu isOpen={isMobileMenuOpen}>
        <MobileMenuItem href={`${homePath}#servicio`} onClick={toggleMobileMenu}>
          Servicio
        </MobileMenuItem>
        <MobileMenuItem href={`${homePath}#cultura`} onClick={toggleMobileMenu}>
          Cultura
        </MobileMenuItem>
        <MobileMenuItem href={`${homePath}#las-personas`} onClick={toggleMobileMenu}>
          Las personas
        </MobileMenuItem>
      </MobileMenu>
    </NavContainer>
  );
};

export default Nav;
