import React from 'react';
import styled from 'styled-components';

const CultureContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 40px;
`

const LeftContent = styled.div`
  width: 60%;
  
  @media (max-width: 768px) {
    width: 100%; // En móviles, el contenedor izquierdo ocupa el 100% del ancho
    padding-left: 0; // Remover padding izquierdo en móviles si es necesario
    text-align: center; // Centrar texto en móviles
  }
`

const FirstContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  @media (max-width: 768px) {
    justify-content: center; // En móviles, centramos el contenido
  }
`

const CultureWord = styled.h2`
  @media (max-width: 768px) {
    display: none; // Oculta el título en pantallas pequeñas
  }
`

const ImageCulture = styled.img`
  max-width: 500px;
  @media (max-width: 768px) {
    max-width: 100%; // En móviles, la imagen se adapta al contenedor
  }
`

const SecondContent = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center; // Centrar el contenido en móviles
  }
`

const TitleSec = styled.h2`
  // Tus estilos para escritorio aquí
`

const PSec = styled.p`
  // Tus estilos para escritorio aquí
`

const Btn = styled.a`
display: inline-block;
font-family: 'Montserrat', sans-serif;
letter-spacing: 1.4px;
padding: 10px 20px;
background-color: #000;
color: white;
font-size: 1.2rem;
border: none;
cursor: pointer;
text-decoration: none;
transition: background-color 0.3s;

&:hover {
  background-color: #333;
}

@media (min-width: 768px) {
  font-size: 1.5rem;
  padding: 10px 20px;
  margin-left: auto;
  margin-right: auto;
}
`

const RightContent = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  @media (max-width: 768px) {
    display: none; // Oculta el contenido derecho en móviles
  }
`

const Linea = styled.img`
  @media (max-width: 768px) {
    display: none; // Oculta la línea en móviles
  }
`

const TruthWord = styled.h2`
  @media (max-width: 768px) {
    display: none; // Oculta este texto en móviles
  }
`

const Culture = () => {
  return (
    <CultureContainer id='cultura'>
      <LeftContent>
        <FirstContent>
          <CultureWord>UNA CULTURA</CultureWord>
          <ImageCulture src="/image-culture-3.png" alt="Perfección Colectiva" />
        </FirstContent>
        <SecondContent>
          <TitleSec>La significativa optimización de todos los sistemas de producción para el ensamblaje de múltiples Plataformas Técnicas alrededor del planeta.</TitleSec>
          <PSec>La permanente búsqueda de la verdad.<br/>
            La mejora continua del producto externo (bienes ofertados), de acuerdo con el propósito más fundamental que cumplen.
            La mejora continua del producto interno (procesos), de acuerdo con el propósito más fundamental que cumplen.
          </PSec>
          <Btn href="https://www.linkedin.com/company/ubicuoxyz/">Leer Más</Btn>
        </SecondContent>
      </LeftContent>
      <RightContent>
        <Linea src="/linea-culture.png" alt="Línea" />
        <TruthWord>UNA VERDAD</TruthWord>
      </RightContent>
    </CultureContainer>
  )
}

export default Culture;
