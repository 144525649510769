import React from 'react';
import styled from 'styled-components';

export const AboutUs = () => {
  const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  `;

  const ImageContent = styled.div`
    margin-right: 20px;
  `;

  const Team = styled.img`
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 400px; /* Tamaño específico para pantallas más grandes */
    }
  `;

  const Content = styled.div`
    flex-grow: 1;
  `;

  const Title = styled.h3`
    font-size: 2rem;

  `;

  const Text = styled.p``;

  return (
    <Container id='las-personas'>
      <ImageContent>
        <Team src="/ubicuo-team.jpg" alt="Equipo de Ubicuo" />
      </ImageContent>

      <Content>
        <Title>Las personas</Title>
        <Text>
        Jorge y Esteban serán los encargados iniciales de la optimización de los sistemas de producción en miras al ensamblaje de la distribuida nueva realidad.

        </Text>
        <Text>Usando las capacidades que tienen para conspirar hacia ese objetivo. </Text>
        <Text>Desde el re-entendimiento conceptual de la realidad, la asimilación acelerada de información, hasta la capacidad de poner en funcionamiento sistemas técnicos funcionales.
</Text>
        <Text>Teniendo en cuenta su disposición vitalicia para la realización de dicho objetivo.</Text>
      </Content>
    </Container>
  );
};
