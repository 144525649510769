import React from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import { Link } from 'react-router-dom';

const HeroWrapper = styled.section`
  background-image: url('/wallpaper-ubicuo.jpg'); 
  background-size: cover;
  background-position: right;
  height: 90vh;
  display: flex;
  flex-direction: column;
`;

const HeroContainer = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Asegura que el contenedor ocupe el 100% de la altura */
`;

const HeroTitle = styled.h1`
margin-bottom: 20px; 
font-size: 2rem;
font-weight: medium;
font-family: 'Montserrat', sans-serif;
text-align: center;

@media (min-width: 768px) {
  font-size: 3rem;
}
`;

const HeroButton = styled.button`
font-family: 'Montserrat', sans-serif;
letter-spacing: 1.4px;
padding: 10px 20px;
background-color: #000; 
color: white;
font-size: 1rem;
border: none;
cursor: pointer;
transition: background-color 0.3s;
margin-top: 20px;

&:hover {
  background-color: #333; // Color al pasar el mouse
}

@media (min-width: 768px) {
  font-size: 1.2rem;
}
`;

const Hero = () => {
  return (
    <HeroWrapper>
      <Nav />
      <HeroContainer>
        <HeroTitle>OPTIMIZAMOS INFRASTRUCTURAS PARA ENSAMBLAR LA NUEVA REALIDAD</HeroTitle>
        
        <Link to="/manifiesto"><HeroButton>Leer manifiesto</HeroButton></Link>
      </HeroContainer>
    </HeroWrapper>
  );
};

export default Hero;